<template>
  <v-card>
    <v-toolbar dense dark color="primary">
      <v-toolbar-title><h4 class="font-weight-light">MONTHLY PROTECT COLLECTION REPORTS</h4>
      </v-toolbar-title>
    </v-toolbar>
    <v-flex xs12 class="mx-2 pt-6">
      <v-row>
        <v-col
          md="12"
          cols="12"
        ></v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-select
            v-model="month_of"
            class="mx-3"
            :items="month_of_items"
            item-value="id"
            item-text="month_of"
            label="Month Of"
            required
            @change="monthly_collection_data"
            dense
          ></v-select>
        </v-col>
      </v-row>
      <v-layout row wrap class="align-center">
        <v-flex xs12 md3 v-if="branch_id>1">
          <v-select
            class="mx-3 pt-5"
            :items="collectors_items"
            item-value="id"
            item-text="full_name"
            label="Collector"
            required
            @change="monthly_collection_data"
            v-model="collector_id"
            dense
          ></v-select>
        </v-flex>
        <v-flex xs12 md3 v-if="branch_id>1">
          <v-select
            v-model="sorted_by"
            class="mx-3 pt-5"
            :items="['Due Date','Name']"
            label="Sorted By"
            required
            @change="monthly_collection_data"
          ></v-select>
        </v-flex>
        <v-flex xs12 md4>
                        <span class="headline mx-3 pt-5">
                                                Print
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_data(0)"
                                                >
                                                {{ icons.mdiPrinter }}
                                                </v-icon>
                                            </span>
          <span class="headline mx-3 pt-5">
                                                Print Unpaid Only
                                                <v-icon
                                                  class="mr-2"
                                                  color="error"
                                                  @click="print_data(1)"
                                                >
                                                {{ icons.mdiPrinter }}
                                                </v-icon>
                                            </span>
          <span class="headline mx-3 pt-5">
                                                Print Paid Only
                                                <v-icon
                                                  class="mr-2"
                                                  color="info"
                                                  @click="print_data(2)"
                                                >
                                                {{ icons.mdiPrinter }}
                                                </v-icon>
                                            </span>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-row>
      <v-col
        md="12"
        cols="12"
      >

      </v-col>

    </v-row>
    <v-card-text>
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-center text-uppercase">
              Name
            </th>
            <th class="text-center text-uppercase">
              Address
            </th>
            <th class="text-center text-uppercase">
              Premium
            </th>
            <th class="text-center text-uppercase">
              Aging
            </th>
            <th class="text-center text-uppercase">
              Due Date
            </th>
            <th class="text-center text-uppercase">
              Payments
            </th>
          </tr>
          </thead>
          <tbody>
          <tr
            v-for="item in data"
            :key="item.id"
          >
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ item.address }}
            </td>
            <td>
              {{ item.premium }}
            </td>
            <td>
              {{ item.aging }}
            </td>
            <td>
              {{ item.due_date }}
            </td>
            <td>
              {{ item.payment }}
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import {mdiAccountSearchOutline, mdiPrinter} from "@mdi/js";

export default {
  setup() {
    return {
      icons: {
        mdiAccountSearchOutline,
        mdiPrinter,
      },
    }
  },
  data() {
    return {
      data: [],
      data2: [],
      month_of_items: [],
      month_of: '',
      category_se: '',
      collectors_items: [],
      collector_id: 0,
      type_of_loan_items: [],
      specify_cat: '',
      sorted_by: '',
      specify_cat_items: [],
    }
  },
  mounted() {
    this.initialize_data()
  },
  watch: {
    branch_id: function () {
      this.initialize_data()
    },
  },
  computed: {
    ...mapGetters('system_data', ['company_logo']),
    ...mapGetters('authentication', ['branch_id', 'branch', 'branch_address', 'branch_contact_no', 'position']),
  },
  methods: {
    ...mapActions('coop_savings_protect_m_c_r_s', ['get_CoopSavingsProtect_current_MCR', 'previous_monthly_collection_report']),
    ...mapActions('transaction_months', ['retrieve_transaction_month']),
    ...mapActions('coop_savings_protect_staff', ['get_CoopSavingsProtectStaff_have_MCR', 'staff_have_prev_mcr']),
    ...mapActions('loans_data', ['type_of_loan_list']),
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    initialize_data() {
      this.type_of_loan_list({
        type: 'All'
      })
        .then(response => {
          this.type_of_loan_items = response.data
          this.category_se = 'All'
        })
      const data3 = new FormData()
      data3.append('branch_id', this.branch_id);
      this.retrieve_transaction_month(data3)
        .then(response => {
          this.month_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
      this.data = []
      this.month_of = ''
      this.category = ''
    },
    async monthly_collection_data() {
      this.data2 = []
      var index = this.month_of_items.map(function (x) {
        return x.id;
      }).indexOf(this.month_of)
      if (index != -1) {
        const data2 = new FormData()
        data2.append('month_of', this.month_of_items[index].month_of);
        data2.append('branch_id', this.branch_id);
        if (index === 0) {
          await this.get_CoopSavingsProtectStaff_have_MCR(data2)
            .then(response => {
              this.collectors_items = response.data
              this.collectors_items.splice(0, 0, {
                id: 0,
                full_name: 'All',
              })
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          await this.staff_have_prev_mcr(data2)
            .then(response => {
              this.collectors_items = response.data
              this.collectors_items.splice(0, 0, {
                id: 0,
                full_name: 'All',
              })
            })
            .catch(error => {
              console.log(error)
            })
        }
        const data = new FormData()
        data.append('branch_id', this.branch_id);
        data.append('month_of', this.month_of_items[index].month_of);
        data.append('is_new_month', 0);
        data.append('collector_staff_id', this.collector_id);
        data.append('sorted_by', this.sorted_by);
        if (index === 0) {
          await this.get_CoopSavingsProtect_current_MCR(data)
            .then(response => {
              console.log(response.data)
              this.data = response.data
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          await this.previous_monthly_collection_report(data)
            .then(response => {
              this.data = response.data
              if (this.specify_cat_items.length === 0) {
                this.specify_cat_items = this.data
              }
            })
            .catch(error => {
              console.log(error)
            })
        }
      }
    },
    print_data(is_unpaid) {
      var imgData = this.company_logo
      var array = []
      array.push(
        [
          {text: 'NAME', alignment: 'center', style: 'label'},
          {text: 'ADDRESS', alignment: 'center', style: 'label'},
          {text: 'PREMIUM', alignment: 'center', style: 'label'},
          {text: 'AGING', alignment: 'center', style: 'label'},
          {text: 'DUE DATE', alignment: 'center', style: 'label'},
          {text: 'PAYMENTS', alignment: 'center', style: 'label'},
        ]
      )

      if (this.data.length > 0) {
        this.data.forEach(function (item, index, payment) {
          array.push(
            [
              {text: item.name, alignment: 'left'},
              {text: item.address, alignment: 'left'},
              {
                text: item.premium,
                alignment: 'left'
              },
              {text: item.aging, alignment: 'center'},
              {text: item.due_date, alignment: 'center'},
              {text: item.payment, alignment: 'center'},
            ]
          )
        })
      } else {
        array.push(
          [
            '----',
            '----',
            '----',
            '----',
            '----',
            '----',
          ]
        )
      }
      //call pdfmake
      var pdfMake = require('pdfmake/build/pdfmake.js')
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require('pdfmake/build/vfs_fonts.js')
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
      }
      var docDefinition = {
        pageSize: {
          width: 612,
          height: 936,
        },
        pageOrientation: 'landscape',
        content: [
          {
            columns: [
              {image: imgData, width: 54, height: 54, style: 'logo'},
              {
                stack: [
                  'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                  {text: this.branch_address, style: 'subheader'},
                  {
                    text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.branch_contact_no,
                    style: 'subheader'
                  },
                ],
                style: 'header'
              },
            ]
          },
          {
            text: 'MONTHLY SAVINGS PROTECT COLLECTION REPORTS' + (is_unpaid ? ' ( UNPAID ONLY )' : ''),
            style: 'title'
          },
          '============================================================================================================',
          {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
          {text: 'Branch: ' + this.branch, style: 'main_info'},
          {
            text: 'Month Of: ' + this.month_of_items[this.month_of_items.map(function (x) {
              return x.id;
            }).indexOf(this.month_of)].month_of, style: 'main_info'
          },
          {text: 'Category: ' + this.category_se, style: 'main_info'},
          {
            text: 'Collector Name: ' + this.collectors_items[this.collectors_items.map(function (x) {
              return x.id;
            }).indexOf(this.collector_id)].full_name, style: 'main_info'
          },
          ' ',
          {
            style: 'tableExample',
            color: '#444',
            table: {
              widths: [150, 200, 45, 85, 45, 45, ],
              body: array,
            },
            layout: {
              fillColor: function (rowIndex) {
                return (rowIndex === 0) ? '#CCCCCC' : null;
              },
            },
          },
          {
            stack: [
              {
                columns: [
                  {text: 'Prepared By: ', style: 'aa_top_margin'},
                  {text: 'Received By:', style: 'cashier_top_margin'},
                  {text: ''},
                ]
              },
              {
                columns: [
                  {text: '', style: 'aa_for'},
                  {text: '', style: 'cashier_for'},
                  {text: ''},
                ]
              },
              {
                columns: [
                  {text: '_________________________________', style: 'aa_line'},
                  {text: '_________________________________', style: 'cashier_line'},
                  {text: ''},
                ]
              },
            ]
          },
          {
            stack: [
              {
                columns: [
                  {text: 'Verified By: ', style: 'bm_top_margin'},
                  {text: 'Audited By: ', style: 'au_top_margin'},
                ]
              },
              {
                columns: [
                  {text: '', style: 'bm_for'},
                  {text: '', style: 'au_for'},
                ]
              },
              {
                columns: [
                  {text: '_________________________________', style: 'bm_line'},
                  {text: '_________________________________', style: 'au_line'},
                ]
              },
              {
                columns: [
                  {text: 'Branch Manager', style: 'bm_placeholder'},
                  {text: 'Asst. Auditor', style: 'au_placeholder'},
                ]
              },
            ]
          },
        ],
        footer: {
          columns: [
            {
              text: 'Generated with Goodlife Savings & Credit Cooperative System',
              alignment: 'center',
              style: 'tableExample'
            }
          ]
        },
        styles: {
          header: {
            fontSize: 21,
            bold: true,
            alignment: 'left',
            margin: [6, 6, 0, 20]//[left, top, right, bottom]
          },
          subheader: {
            fontSize: 12
          },
          title: {
            fontSize: 15,
            alignment: 'center',
          },
          logo: {
            alignment: 'center',
            margin: [0, 0, 0, 0]//[left, top, right, bottom]
          },
          printed_label: {
            alignment: 'right',
            fontSize: 9,
            margin: [0, 4, 0, 0]//[left, top, right, bottom]
          },
          main_info: {
            margin: [0, 2, 0, 2],
            fontSize: 10,
          },
          sub_info: {
            margin: [0, 2, 0, 2],
            fontSize: 8,
          },
          tableExample: {
            fontSize: 7,
          },

          aa_top_margin: {
            margin: [0, 10, 0, 0],
            alignment: 'left',
            fontSize: 7,
          },
          cashier_top_margin: {
            margin: [0, 10, 0, 0],
            alignment: 'left',
            fontSize: 7,
          },
          bm_top_margin: {
            margin: [0, 10, 0, 0],
            alignment: 'left',
            fontSize: 7,
          },
          cs_top_margin: {
            margin: [0, 10, 0, 0],
            alignment: 'left',
            fontSize: 7,
          },
          aa_line: {
            margin: [0, 0, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          cashier_line: {
            margin: [0, 0, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          cs_line: {
            margin: [0, 0, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          bm_line: {
            margin: [0, 0, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          aa_placeholder: {
            margin: [15, 2, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          aa_for: {
            margin: [0, 15, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          cs_for: {
            margin: [2, 15, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          cashier_for: {
            margin: [3, 15, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          bm_for: {
            margin: [3, 15, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          cashier_placeholder: {
            margin: [26, 2, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          cs_placeholder: {
            margin: [2, 2, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          bm_placeholder: {
            margin: [5, 2, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          au_top_margin: {
            margin: [0, 10, 0, 0],
            alignment: 'left',
            fontSize: 7,
          },
          au_placeholder: {
            margin: [2, 2, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          au_for: {
            margin: [2, 15, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
          au_line: {
            margin: [0, 0, 0, 2],
            alignment: 'left',
            fontSize: 7,
          },
        },
      }
      pdfMake.createPdf(docDefinition).open();
    },
  },
}
</script>
